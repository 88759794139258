<template>
  <div>
    <Nav @getCurrency="changeCurrency" @selectedCurrency="selectedCur" />

    <Tittle :type="'courses'">
      <h1 class="fadeInUp"><span></span>Online courses</h1>
    
    </Tittle>
    <!-- /filters -->
    <div class="container margin_60_35">
      <div class="row">
        <aside class="col-lg-3" id="sidebar">
          <div id="filters_col">
            <a
              data-toggle="collapse"
              href="#collapseFilters"
              aria-expanded="false"
              aria-controls="collapseFilters"
              id="filters_col_bt"
              >Filters
            </a>
            <div class="collapse show" id="collapseFilters">
              <div class="filter_type">
                <h6>Category</h6>
                <router-link :to="'/allcourses/e'">
                  <h6 :class="{ active: picked == 'e' }" @click="holla">
                    <i class="icon-fixed-width icon-book"></i> All
                  </h6>
                </router-link>
                <router-link
                  :to="'/allcourses/free'"
                >
                  <h6 :class="{ active: picked == 'free' }">
                    <i class="icon-fixed-width icon-book"></i> Free
                  </h6>
                </router-link>
                <router-link
                  :to="'/allcourses/' + c._id"
                  v-for="c in categories"
                  :key="c"
                >
                  <h6 :class="{ active: picked == c._id }">
                    <i class="icon-fixed-width icon-book"></i> {{ c.name }}
                  </h6>
                </router-link>
              </div>
            </div>
            <!--/collapse -->
          </div>
          <!--/filters col-->
        </aside>
        <!-- /aside -->

        <div class="col-lg-9">
          <div class="row">
            <Loading v-if="Loading" />
            <div class="col-md-6" v-for="cos in courses" :key="cos">
              <router-link :to="'/coursedetail/' + cos._id">
                <div class="box_grid wow">
                  <figure class="block-reveal">
                    <div class="block-horizzontal"></div>
                    <!-- <a href="#0" class="wish_bt"></a> -->
                    <a href="">
                      <CourseImage
                        :name="cos.thumbnailFile"
                        style="height: 250px; width: 100%"
                      />
                    </a>
                    <div class="price" v-if="cos.price.$numberDecimal != 0">
                      <span v-if="rate">
                        <span v-if="selectedCurrency === 'GBP'">
                          £{{
                            (cos.price.$numberDecimal * parseFloat(rate))
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </span>
                        <span v-if="selectedCurrency === 'NGN'">
                          ₦{{
                            (cos.price.$numberDecimal * parseFloat(rate))
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </span>
                        <span v-if="selectedCurrency === 'USD'">
                          ${{
                            (cos.price.$numberDecimal * parseFloat(rate))
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </span>

                        <br />
                        <small>
                          <Discount
						  :rate = rate
                            :selectedCurrency="selectedCurrency"
                            :id="cos._id"
                            :price="cos.price.$numberDecimal"
                            :discount="cos.discountPercent"
                        /></small>
                      </span>
                      <span v-else>
                        ₦{{
                          cos.price.$numberDecimal
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                        <br />
                        <small>
                          <Discount
                            :id="cos._id"
                            :price="cos.price.$numberDecimal"
                            :discount="cos.discountPercent"
                        /></small>
                      </span>
                    </div>

                    <div class="price" v-else>FREE</div>
                    <div class="preview"><span>Preview course</span></div>
                  </figure>
                  <div class="wrapper">
                    <small>{{ cos.category[0].name }}</small>
                    <h3>{{ cos.title }}</h3>
                    <p>{{ cos.secondTitle }}</p>
                    <Ratings :id="cos._id" :type="'course'" />
                  </div>
                  <ul>
                    <li style="font-size: 14px">
                      <i class="icon_profile"></i>
                      {{ cos.user[0].lastName.substring(0, 21) }}
                    </li>
                    <!-- <li><i class="icon_like"></i></li> -->
                    <li><a>Enroll now</a></li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
          <!-- /row -->
          <!-- <p class="text-center" @click.prevent="fetchMore" v-if="!Loading">
            <a href="" class="btn_1 rounded add_top_30">Load more</a>
          </p> -->
          <nav aria-label="...">
            <ul class="pagination pagination-sm add_bottom_30">
              <li class="page-item" v-if="page>1" @click="fetchNew(page-1)">
                <a class="page-link"  >Previous</a>
              </li>
              <li class="page-item" v-for="p in pages" :key="p">
                <a class="page-link" :class="{activePage: p==page}" @click="fetchNew(p)" >{{p}}</a></li>
                <li class="page-item" v-if="page<numberOfPages" @click="fetchNew(page+1)">
                <a class="page-link">Next </a>
              </li>
              <li class="page-item">
                <a class="page-link" > total record : {{total}}</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
    <!-- <div class="bg_color_1">
			<div class="container margin_60_35">
				<div class="row">
					<div class="col-md-4">
						<a href="#0" class="boxed_list">
							<i class="pe-7s-help2"></i>
							<h4>Need Help? Contact us</h4>
							<p>Cum appareat maiestatis interpretaris et, et sit.</p>
						</a>
					</div>
					<div class="col-md-4">
						<a href="#0" class="boxed_list">
							<i class="pe-7s-wallet"></i>
							<h4>Payments and Refunds</h4>
							<p>Qui ea nemore eruditi, magna prima possit eu mei.</p>
						</a>
					</div>
					<div class="col-md-4">
						<a href="#0" class="boxed_list">
							<i class="pe-7s-note2"></i>
							<h4>Quality Standards</h4>
							<p>Hinc vituperata sed ut, pro laudem nonumes ex.</p>
						</a>
					</div>
				</div>
			</div>
		</div> -->
    <!-- /bg_color_1 -->

    <Footer />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Discount from "@/components/Discount";
import Nav from "@/components/site/Nav.vue";
import Ratings from "@/components/site/Ratings.vue";
import Loading from "@/components/Loading";
import Tittle from "@/components/site/Tittle.vue";
import Footer from "@/components/site/Footer.vue";
import CourseImage from "@/components/CourseImage";
export default {
  name: "Allcourses",
  data() {
    return {
      category: "",
      courses: "",
      picked: "e",
      Loading: false,
      searchItem:'',
      categories: "",
      page: 1,
      pages: [],
      numberOfPages: 0,
      total: 0,
      limit: 10,
      rate: null,
      selectedCurrency: "",
    };
  },
  watch: {
    $route() {
      this.picked = this.$route.params.cat;
      this.page = 1;
      this.Loading = true;
      this.fetchAllCategoryCoursesPagination__public({
        cat: this.picked,
        page: this.page,
      }).then((res) => {
        this.courses = res.data.courses
        this.total = res.data.total
        this.getPages();
        this.Loading = false;
      });
    },

    circle: function () {
      if (this.circle == "loading") {
        this.Loading = true;
      } else {
        this.Loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["circle"]),
    ...mapGetters(["backEnd"]),
  },
  components: {
    Nav,
    Tittle,
    Loading,
    Ratings,
    CourseImage,
    Discount,
    Footer,
  },
  methods: {
    ...mapActions(["fetch_courses_by_category__public","fetchAllCategoryCoursesPagination__public"]),
    ...mapActions(["fetch_categories__public"]),
    getPages() {
      this.pages = []
      this.numberOfPages = ~~(this.total/this.limit)
      if((this.total/this.limit)>this.numberOfPages){
        this.numberOfPages = this.numberOfPages + 1
      }
      for (let i = 1; i <= this.numberOfPages; i++) {
        this.pages.push(i)
      }
    },
    fetchNew(page) {
      this.page = page;
      this.Loading = true;
      this.fetchAllCategoryCoursesPagination__public({
        cat: this.picked,
        page: this.page,
      }).then(res => {
        this.courses = res.data.courses
        this.total = res.data.total
        this.getPages();
        this.Loading = false;
        window.scrollTo(0,0)
      })
    },
    changeCurrency(rate) {
      this.rate = rate;
    },
    selectedCur(cur) {
      this.selectedCurrency = cur;
    },
  },

  mounted() {
    let js = document.createElement("script");
    js.setAttribute("src", "js/main.js");
    document.head.appendChild(js);
    let jsc = document.createElement("script");
    jsc.setAttribute("src", "js/common_scripts.js");
    document.head.appendChild(jsc);

    this.fetch_categories__public().then((res) => {
      this.categories = res.data;
    });

    this.category = this.$route.params.cat;
    this.picked = this.$route.params.cat;
    this.fetchAllCategoryCoursesPagination__public({
      cat: this.category,
      page: this.page,
    }).then((res) => {
      this.courses = res.data.courses
      this.total = res.data.total
      this.getPages();
      this.Loading = false;
    });
  },
};
</script>
<style scoped>
.active {
  padding: 15px;
  color: white;
  background-color: #ef6421;
  border-radius: 20%;
}

.activePage{
  color: white;
  background-color: #ef6421;  
}
</style>